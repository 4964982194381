import request from '@/utils/request'
import { getLoginAddress, getExcelExport } from '@/utils/auth'

// 结存计算结果分页查询
export function pageResult(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/invoicing/result/page`,
    method: 'get',
    params
  })
}
// PUT重新计算结果
export function recalculate(id) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/invoicing/recalculate/${id}`,
    method: 'put'
  })
}
// 结存计算 详情分页查询
export function pageUnFinish(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/invoicing/unfinish/page`,
    method: 'get',
    params
  })
}
// 库存结存分页查询
export function pageInvoicing(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/invoicing/page`,
    method: 'get',
    params
  })
}
// 库存结存出库详情分页查询
export function pageDetails(params) {
  return request({
    url: `${process.env.VUE_APP_IMS_SC_API}api/invoicing/detail/page`,
    method: 'get',
    params
  })
}

// 差异处理分页查询
export function pageAbnormalTask(params) {
  return request({
    url: `abnormalTask/page`,
    method: 'get',
    params
  })
}
// 差异处理
export function handleHandleCode(data) {
  return request({
    url: `abnormalTask/handleHandleCode`,
    method: 'post',
    data
  })
}
// 差异明细关联出入库查询
export function findTaskId(data) {
  return request({
    url: `abnormalTask/findTaskId`,
    method: 'post',
    data
  })
}

// 差异明细查询
export function findAbnormalTaskDetail(handleCode) {
  return request({
    url: `abnormalTask/findAbnormalTaskDetail/${handleCode}`,
    method: 'get'
  })
}
// 在途库存管理
export function getOnwayTask(params) {
  return request({
    url: `onwayTask/page`,
    method: 'get',
    params
  })
}
// 在途库存管理-导入
export function importOnwayTask(data) {
  return request({
    url: `onwayTask/import`,
    method: 'post',
    data
  })
}
// 查看物理仓和逻辑仓的关系
export function getWarehouseLogictWarehouse(data) {
  return request({
    url: `${getLoginAddress()}wms-base/basewarehouse/findWarehouseLogictWarehouse`,
    method: 'post',
    data
  })
}
// 在途库存管理---导出
export function postOnwayTask(data) {
  return request({
    url: `${getExcelExport()}excel/export`,
    method: 'post',
    data
  })
}
